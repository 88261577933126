import React from "react";
import { Layout } from "../components/Layout/Layout";
import { graphql, PageProps } from "gatsby";
import { PartialBlogPost } from "../types/blog";
import { Hero } from "../components/Hero/Hero";
import { Card } from "../components/Card/Card";
import { SEO } from "../components/SEO/SEO";

import styles from "./blog.module.scss";

type BlogPageProps = PageProps<{
  allPosts: {
    nodes: PartialBlogPost[];
  };
}>;

const shouldShowPost = (post: PartialBlogPost) =>
  process.env.NODE_ENV !== "production" || !post.frontmatter.draft;

export const BlogPage = ({ data: { allPosts } }: BlogPageProps) => {
  return (
    <Layout>
      <SEO title="Blog" />
      <Hero heading="Blog" lead="Dev, tech and accessibility" />
      <nav aria-label="Blog posts" className="container">
        <ol className={styles.list}>
          {allPosts.nodes.filter(shouldShowPost).map((post) => (
            <li key={post.id}>
              <Card item={post} basePath="blog" />
            </li>
          ))}
        </ol>
      </nav>
    </Layout>
  );
};

export default BlogPage;

export const allPostsQuery = graphql`
  {
    allPosts: allMdx(
      filter: { fileAbsolutePath: { regex: "/blog/" } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      nodes {
        ...PartialBlogPost
      }
    }
  }
`;
